import * as React from 'react'
import {useForm} from 'react-hook-form'
import {BiMailSend} from 'react-icons/bi'
import {trim} from 'lodash'
import {useRouter} from 'next/router'
import tw from 'twin.macro'

import {Alert} from '@/client/core/components/alert'
import {Button} from '@/client/core/components/button'
import {Spinner} from '@/client/core/components/feedback'
import {Input} from '@/client/core/components/input'
import {Spacer} from '@/client/core/components/layout'

import {
  useConfirmLoginCode,
  useSendLoginCode,
} from '../utils/use-send-login-code'

/**
 * Used on the Login and Sign Up screens to handle authentication. Can be shared
 * between those as Supabase magic link doesn't differentiate between logging in
 * and signing up.
 */
function AuthenticationForm({
  buttonLabel = '로그인',
  inviteCode,
  inviteToken,
  continueUrl,
  onEmailLoginRequest,
}: {
  buttonLabel?: string
  inviteCode?: string
  inviteToken?: string
  continueUrl?: string
  onEmailLoginRequest: (email: string) => void
}) {
  const sendLoginCode = useSendLoginCode()
  const {
    register,
    handleSubmit,
    setError,
    setFocus,
    formState: {
      errors: {email: error},
      isSubmitting,
    },
  } = useForm<{email: string}>()

  React.useEffect(() => {
    setFocus('email')
  }, [setFocus])

  return (
    <form
      tw="flex flex-col items-center space-y-1"
      onSubmit={handleSubmit(async ({email}) => {
        try {
          if (email.split('@')[1] !== 'concseoul.com') {
            setError('email', {
              message: '콩크 이메일을 사용해주세요.',
            })
            return
          }
          await sendLoginCode({
            email,
            inviteCode,
            inviteToken,
            continueUrl,
          })
          onEmailLoginRequest(email)
        } catch (err) {
          setError('email', {
            message:
              err.status === 429
                ? '로그인 이메일은 60초에 1회 요청할 수 있습니다.'
                : err.message,
          })
        }
      })}
    >
      {error?.message && <Alert tw="mb-1 w-full">{error.message}</Alert>}
      <Input
        hideLabel
        label="Email"
        type="email"
        placeholder="이메일"
        autoComplete="off"
        cx={tw`w-full`}
        {...register('email', {required: '이메일 주소를 입력해 주세요.'})}
      />
      <Button
        type="submit"
        tw="w-full"
        size="lg"
        disabled={isSubmitting}
        suffix={isSubmitting && Spinner}
      >
        {buttonLabel}
      </Button>
    </form>
  )
}

function AuthenticationConfirmForm({email}: {email: string}) {
  const router = useRouter()
  const confirmLoginCode = useConfirmLoginCode()
  const {
    register,
    handleSubmit,
    setError,
    setFocus,
    formState: {
      errors: {code: error},
      isSubmitting,
    },
  } = useForm<{code: string}>()

  React.useEffect(() => {
    setFocus('code')
  }, [setFocus])

  return (
    <form
      tw="flex flex-col items-center space-y-1"
      onSubmit={handleSubmit(async data => {
        try {
          const code = trim(data.code.replace(/-/g, ''))
          const {actionUrl} = await confirmLoginCode({email, code})
          router.push(actionUrl)
        } catch (err) {
          setError('code', {message: err.message})
        }
      })}
    >
      {error?.message && <Alert tw="my-1 w-full">{error.message}</Alert>}
      <Input
        hideLabel
        label="Code"
        type="text"
        placeholder="코드"
        autoComplete="off"
        cx={tw`w-full`}
        {...register('code', {required: '이메일로 받은 코드를 입력해주세요.'})}
      />
      <Button
        type="submit"
        tw="w-full"
        size="lg"
        disabled={isSubmitting}
        suffix={isSubmitting && Spinner}
      >
        로그인 코드로 계속 진행
      </Button>
    </form>
  )
}

function CheckMailbox({email}: {email: string}) {
  return (
    <>
      <div tw="flex flex-col items-center text-center">
        <BiMailSend tw="text-4xl text-yellow-500" />
        <h2 tw="heading-lg">메일함을 확인하세요</h2>
        <p>
          계정 활성화 코드를{' '}
          {email ? (
            <>
              <span tw="font-bold">{email}</span>로
            </>
          ) : (
            <>이메일로</>
          )}{' '}
          전송했습니다. 이 코드는 곧 만료되니 바로 입력해주세요.
        </p>
      </div>

      <AuthenticationConfirmForm email={email} />

      <Spacer y={4} />

      <p tw="text-center caption">
        메일을 받지 못했나요? 스팸 폴더를 확인해보세요.
      </p>
    </>
  )
}

export {AuthenticationForm, CheckMailbox}
