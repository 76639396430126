import {client} from '@/client/core/utils/api-client'

function useSendLoginCode() {
  return (data: {
    email: string
    inviteCode?: string
    inviteToken?: string
    continueUrl?: string
  }) => client<void>('/api/auth/magiclink', {data})
}

function useConfirmLoginCode() {
  return (data: {email: string; code: string}) =>
    client<{actionUrl: string}>('/api/auth/magiclink/confirm', {data})
}

export {useConfirmLoginCode, useSendLoginCode}
