import * as React from 'react'
import toast from 'react-hot-toast'
import type {GetServerSideProps} from 'next'
import {useRouter} from 'next/router'

import {
  AuthenticationForm,
  CheckMailbox,
} from '@/client/auth/components/authentication-form'
import {UnauthenticatedApp} from '@/client/auth/components/unauthenticated-app'
import {useClearAppData} from '@/client/auth/utils/logout'
import {Card, Spacer} from '@/client/core/components/layout'
import {FocusLayout} from '@/client/site/components/focus-layout'
import {checkInvitation} from '@/server/auth/accept-invitation'
import type {Invitation} from '@/types'

import 'twin.macro'

export const getServerSideProps: GetServerSideProps = async ({query}) => {
  try {
    const {token: inviteToken} = query
    if (!inviteToken) {
      return {
        props: {},
      }
    }
    const invitation = await checkInvitation(inviteToken.toString())
    if (!invitation) throw new Error(`Invalid invitation token.`)
    return {
      props: {
        invitation,
      },
    }
  } catch (err) {
    console.error(err)
    return {
      props: {},
    }
  }
}

function WelcomeMessage({invitation}: {invitation?: Invitation}) {
  if (invitation) {
    const {invite, type} = invitation
    const person = invite?.user?.name ?? 'Someone'
    const project =
      (type === 'list'
        ? invite?.list?.name
        : type === 'team'
        ? invite?.team?.name
        : null) ?? 'Conc'

    return (
      <>
        <h2 tw="heading-lg">
          {person} 님이 {project} 팀에 당신을 초대했습니다!
        </h2>
        <p>
          콩크 계정을 만들어 {project} 팀에 참여하세요. 함께 사용하는 {project}{' '}
          팀의 작업 공간에서 팀의 믹스테잎을 보거나, 새로운 믹스테잎을 만들 수
          있습니다. 지금 시작하세요!
        </p>
      </>
    )
  }
  return <h2 tw="heading">어서 오세요!</h2>
}

function LoginPage({invitation}: {invitation?: Invitation}) {
  const clearAppData = useClearAppData()

  const router = useRouter()
  const continueUrl = router.query.r?.toString()
  const inviteToken = router.query.token?.toString()
  const [requestedEmail, setRequestedEmail] = React.useState('')

  React.useEffect(() => {
    if (router.query.error === 'expired') {
      toast.error(
        `만료된 로그인 코드입니다. 이미 사용했거나 사용기간이 지났을 수 있습니다. 다시 로그인해주세요.`,
      )
      router.replace('/login')
    }
  }, [router.query.error, router, requestedEmail])

  React.useEffect(() => {
    // In case user clears cookies or gets disabled remotely, user will be
    // signed out without proper post sign out process using useLogout hook.
    // This attempts to reset app states that can be done without user context
    clearAppData()
  }, [clearAppData])

  return (
    <FocusLayout>
      <main tw="container-md">
        <Card variant="focus">
          {requestedEmail ? (
            <CheckMailbox email={requestedEmail} />
          ) : (
            <>
              <div tw="flex flex-col items-center text-center">
                <WelcomeMessage invitation={invitation} />
              </div>

              <Spacer y={2} />

              <AuthenticationForm
                continueUrl={continueUrl}
                inviteToken={inviteToken}
                onEmailLoginRequest={email => setRequestedEmail(email)}
              />
            </>
          )}
        </Card>
      </main>
    </FocusLayout>
  )
}

LoginPage.Layout = UnauthenticatedApp

export default LoginPage
