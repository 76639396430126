import {useId} from '@reach/auto-id'
import type {TwStyle} from 'twin.macro'
import tw from 'twin.macro'

import {forwardRefWithAs, PropsWithAs} from '../utils'

import {inputBaseStyles, inputSizeStyles, inputStyles} from './input-styles'

type InputProps = {
  variant?: keyof typeof inputStyles
  size?: keyof typeof inputSizeStyles
  prefix?: keyof JSX.IntrinsicElements | React.ComponentType | false
  suffix?: keyof JSX.IntrinsicElements | React.ComponentType | false
  label: string
  hideLabel?: boolean
  cx?: TwStyle
}

function InputComponent(
  props: PropsWithAs<InputProps, 'input'>,
  ref: React.Ref<HTMLInputElement>,
) {
  const id = useId(props.name)

  const {
    as: Component = 'input',
    variant = 'outline',
    size = 'md',
    type: typeProp,
    prefix: PrefixIcon,
    suffix: SuffixIcon,
    label,
    hideLabel,
    cx,
    ...otherProps
  } = props

  const type = typeProp ?? (Component === 'input' ? 'text' : undefined)
  const borderStyles = size !== 'none' && tw`rounded`

  return (
    <div tw="w-56" css={cx}>
      <label
        htmlFor={id}
        tw="block text-sm font-medium text-gray mb-1"
        css={hideLabel && tw`sr-only`}
      >
        {label}
      </label>

      <div tw="relative">
        {PrefixIcon && (
          <div tw="absolute inset-y-0 left-0 pl-2 flex items-center pointer-events-none">
            <PrefixIcon tw="h-5 w-5 text-muted" aria-hidden="true" />
          </div>
        )}

        <Component
          id={id}
          ref={ref}
          type={type}
          css={[
            inputBaseStyles,
            borderStyles,
            inputStyles[variant].default,
            inputSizeStyles[size],
            PrefixIcon && tw`pl-4`,
            SuffixIcon && tw`pr-4`,
            {
              '&.error': inputStyles[variant].error,
            },
          ]}
          {...otherProps}
        />

        {SuffixIcon && (
          <div tw="absolute inset-y-0 right-0 pr-2 flex items-center pointer-events-none">
            <SuffixIcon tw="h-5 w-5 text-muted" aria-hidden="true" />
          </div>
        )}
      </div>
    </div>
  )
}

/**
 * Primary UI component for user input
 */
const Input = forwardRefWithAs<InputProps, 'input'>(InputComponent)

export type {InputProps}
export {Input}
