import {Global} from '@emotion/react'
import Link from 'next/link'
import tw from 'twin.macro'

import {Logo} from '../../core/components/logo'

function FocusLayout({
  bg,
  children,
}: {
  bg?: boolean
  children: React.ReactNode
}) {
  return (
    <>
      <Global
        styles={{
          html: tw`font-size[16px]`,
          body:
            bg &&
            tw`background[url(/assets/brushstrokes.svg) center center/auto 100% no-repeat] sm:background[url(/assets/brushstrokes.svg) center -2rem/125% 125% no-repeat] md:background[url(/assets/brushstrokes.svg) center -2rem/100% 115% no-repeat] lg:background[url(/assets/brushstrokes.svg) center 0rem/100% 100% no-repeat]`,
        }}
      />
      <div tw="h-20 flex items-center justify-center">
        <Link href="/">
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a>
            <Logo />
          </a>
        </Link>
      </div>
      <div tw="flex items-center justify-center min-height[calc(100vh - 12rem)]">
        {children}
      </div>
    </>
  )
}

export {FocusLayout}
