import tw from 'twin.macro'

/**
 * Make input ready to consume custom styles
 */
const inputBaseStyles = tw`block w-full border-transparent bg-background focus:(ring-transparent border-transparent) placeholder-gray`

/**
 * Input variant styles
 */
const inputStyles = {
  outline: {
    default: tw`border-muted focus:border-muted`,
    error: tw`border-error placeholder-error-text`,
  },
  solid: {
    default: tw`bg-washed`,
    error: tw`bg-error-background placeholder-error-text`,
  },
  none: {
    default: null,
    error: tw`placeholder-error-text`,
  },
}

/**
 * Input size styles
 */
const inputSizeStyles = {
  md: tw`padding-left[12px] padding-right[12px] padding-top[8px] padding-bottom[8px]`,
  sm: tw`padding-left[12px] padding-right[12px] padding-top[4px] padding-bottom[4px]`,
  none: tw`p-0`,
}

export {inputBaseStyles, inputSizeStyles, inputStyles}
