import {useRouter} from 'next/router'

import {useUser} from '@/client/auth/context'

/**
 * Redirects user to the homepage. We don't check any scope cookie or current
 * team here as the homepage handles this on the server-side
 */
function UnauthCheck({children}: {children: React.ReactNode}) {
  const router = useRouter()
  const {isLoading, user} = useUser()

  if (isLoading) return null
  if (user) {
    if (process.browser) router.push('/')
    return null
  }

  return <>{children}</>
}

export {UnauthCheck}
